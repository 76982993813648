import { configureStore, combineReducers } from "@reduxjs/toolkit";
import quiz from "./reducers/Quiz";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const quizPersistConfig = {
  key: "quiz",
  storage,
  whitelist: ["code", "token", "planId"],
};

const rootReducer = combineReducers({
  quiz: persistReducer(quizPersistConfig, quiz),
});

const persistedReducer = persistReducer(quizPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
