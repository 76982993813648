import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialCode = JSON.parse(localStorage.getItem("code")) || "";
const initialToken = JSON.parse(localStorage.getItem("token")) || "";
const initialPlanID = JSON.parse(localStorage.getItem("planId")) || "";

const initialState = {
  answerIndex: null,
  answerIndexes: [null, null, null, null, null, null, null],
  heightScale: "CM",
  weightScale: "KG",
  inputValue: {
    height_ft: "",
    height_in: "",
    height_cm: "",
    weight_current_lbs: "",
    weight_current_kg: "",
    weight_goal_lbs: "",
    weight_goal_kg: "",
    number: "",
    text: "",
  },
  code: initialCode,
  aid: "",
  QuizResult: {},
  CheckoutResult: null,
  PreCheckoutResult: null,
  inputError: false,
  token: initialToken,
  type: "",
  checkout: false,
  checkout2: false,
  loading: false,

  planSelectedIndex: 0,
  planId: initialPlanID,
  discount: true,
  faqIndex: null,
  login: false,
  phoneNumber: "",
  pay: false,
};

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ANSWER_QUESTION:
      return updateObject(state, {
        answerIndex: action.index,
        answerIndexes: state.answerIndexes.map((item, index) => {
          // eslint-disable-next-line
          if (index == action.index) {
            return item === null ? action.aid : null;
          } else {
            return item;
          }
        }),
      });
    case actionTypes.ON_SCALE_CHANGE:
      return updateObject(state, {
        heightScale: state.heightScale === "FT" ? "CM" : "FT",
        weightScale: state.weightScale === "LBS" ? "KG" : "LBS",
      });
    case actionTypes.ON_INPUT_CHANGE:
      return action.HorW === "height"
        ? updateObject(state, {
            inputValue: {
              ...state.inputValue,
              height_cm:
                action.scale === "cm"
                  ? action.value
                  : state.inputValue.height_cm,
              height_ft:
                action.scale === "ft"
                  ? action.value
                  : state.inputValue.height_ft,
              height_in:
                action.scale === "in"
                  ? action.value
                  : state.inputValue.height_in,
            },
          })
        : action.HorW === "weightCurrent"
        ? updateObject(state, {
            inputValue: {
              ...state.inputValue,
              weight_current_lbs:
                action.scale === "lbs"
                  ? action.value
                  : state.inputValue.weight_current_lbs,
              weight_current_kg:
                action.scale === "kg"
                  ? action.value
                  : state.inputValue.weight_current_kg,
            },
          })
        : action.HorW === "weightGoal"
        ? updateObject(state, {
            inputValue: {
              ...state.inputValue,
              weight_goal_lbs:
                action.scale === "lbs"
                  ? action.value
                  : state.inputValue.weight_goal_lbs,
              weight_goal_kg:
                action.scale === "kg"
                  ? action.value
                  : state.inputValue.weight_goal_kg,
            },
          })
        : action.HorW === "number"
        ? updateObject(state, {
            inputValue: {
              ...state.inputValue,
              number: action.value,
            },
          })
        : updateObject(state, {
            inputValue: {
              ...state.inputValue,
              text: action.value,
            },
          });
    case actionTypes.SAVE_FETCHED_QUIZ_DATA:
      return updateObject(state, {
        QuizResult: action.result,
      });
    case actionTypes.SAVE_FETCHED_CHECKOUT_DATA:
      return updateObject(state, {
        CheckoutResult: action.result,
      });
    case actionTypes.SAVE_FETCHED_PRECHECKOUT_DATA:
      return updateObject(state, {
        PreCheckoutResult: action.result,
      });
    case actionTypes.NULL_ANSWER_INDEX:
      return updateObject(state, {
        answerIndex: null,
        answerIndexes: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
      });
    case actionTypes.GO_NEXT:
      return updateObject(state, {
        QuizResult: action.result,
      });
    case actionTypes.SHOW_INPUT_ERROR:
      return updateObject(state, {
        inputError: true,
      });
    case actionTypes.HIDE_INPUT_ERROR:
      return updateObject(state, {
        inputError: false,
      });
    case actionTypes.SAVE_TYPE:
      return updateObject(state, { type: action.linkType });
    case actionTypes.GO_TO_CHECKOUT:
      return updateObject(state, { checkout: true, checkout2: false });
    case actionTypes.GO_TO_CHECKOUT2:
      return updateObject(state, { checkout2: true, checkout: false });
    case actionTypes.LOADING_TRUE:
      return updateObject(state, { loading: true });
    case actionTypes.LOADING_FALSE:
      return updateObject(state, { loading: false });
    case actionTypes.SAVE_CODE_TOKEN:
      return updateObject(state, { code: action.code, token: action.token });

    case actionTypes.CHOOSE_PLAN:
      return updateObject(state, { planSelectedIndex: action.index });
    case actionTypes.SAVE_PLAN_ID:
      return updateObject(state, {
        planId: state.CheckoutResult.plans[state.planSelectedIndex].planId,
      });
    case actionTypes.NO_DISCOUNT:
      return updateObject(state, { discount: false });
    case actionTypes.SELECT_FAQ:
      return state.faqIndex === action.index
        ? updateObject(state, { faqIndex: null })
        : updateObject(state, { faqIndex: action.index });
    case actionTypes.GO_TO_LOGIN:
      return updateObject(state, { login: true });
    case actionTypes.ON_LOGIN_INPUT_CHANGE:
      return updateObject(state, { phoneNumber: action.value });
    case actionTypes.GO_TO_PAY:
      return updateObject(state, { pay: true, link: action.link });
    case actionTypes.LEFT_LOGIN:
      return updateObject(state, { login: false });
    default:
      return state;
  }
};

export default quizReducer;
