export const SAVE_TYPE = "SAVE_TYPE";
export const ANSWER_QUESTION = "ANSWER_QUESTION";
export const ON_SCALE_CHANGE = "ON_SCALE_CHANGE";
export const ON_INPUT_CHANGE = "ON_INPUT_CHANGE";
export const SAVE_FETCHED_QUIZ_DATA = "SAVE_FETCHED_QUIZ_DATA";
export const SAVE_FETCHED_CHECKOUT_DATA = "SAVE_FETCHED_CHECKOUT_DATA";
export const SAVE_FETCHED_PRECHECKOUT_DATA = "SAVE_FETCHED_PRECHECKOUT_DATA";
export const GO_NEXT = "GO_NEXT";
export const SHOW_INPUT_ERROR = "SHOW_INPUT_ERROR";
export const HIDE_INPUT_ERROR = "HIDE_INPUT_ERROR";
export const GO_TO_CHECKOUT = "GO_TO_CHECKOUT";
export const GO_TO_CHECKOUT2 = "GO_TO_CHECKOUT2";
export const NULL_ANSWER_INDEX = "NULL_ANSWER_INDEX";
export const SAVE_CODE_TOKEN = "SAVE_CODE_TOKEN";

export const CHOOSE_PLAN = "CHOOSE_PLAN";
export const SAVE_PLAN_ID = "SAVE_PLAN_ID";
export const NO_DISCOUNT = "NO_DISCOUNT";
export const SELECT_FAQ = "SELECT_FAQ";
export const GO_TO_LOGIN = "GO_TO_LOGIN";
export const ON_LOGIN_INPUT_CHANGE = "ON_LOGIN_INPUT_CHANGE";
export const GO_TO_PAY = "GO_TO_PAY";
export const LEFT_LOGIN = "LEFT_LOGIN";

export const LOADING_TRUE = "LOADING_TRUE";
export const LOADING_FALSE = "LOADING_FALSE";
